<template>
  <div class="list-table-body-row" ref="target" data-e2e="list-table-body-row">
    <list-table-row-item
      v-for="item in rowItems"
      :key="item.itemId"
      v-bind="item"
    />
    <base-button-border-orange
      v-if="showPrintButton"
      :disabled="printButtonDisabled"
      :styles="{ width: '120px', marginRight: '20px' }"
      @click.stop="clickPrint"
      >証明書印刷</base-button-border-orange
    >
  </div>
</template>

<script>
import ListTableRowItem from '@/components/parts/atoms/ListTableRowItem.vue'
import BaseButtonBorderOrange from '../atoms/BaseButtonBorderOrange.vue'

export default {
  name: 'ListTableBodyRow',

  components: {
    ListTableRowItem,
    BaseButtonBorderOrange
  },

  props: {
    intersectionObserverFlg: { type: Boolean, default: false },
    initialDisplayFlg: { type: Boolean },
    rowItems: { type: Array },
    id: { type: Number },
    printButtonDisabled: { type: Boolean, default: true },
    showPrintButton: { type: Boolean, default: false },
    number: { type: Number, default: 0 },
    lastIndex: { type: Number, default: 0 }
  },

  mounted() {
    if (this.intersectionObserverFlg) {
      const target = this.$refs.target
      let observer = new IntersectionObserver(() => {
        this.emitScrollBottom()
      })
      observer.observe(target)
    }
  },

  methods: {
    clickPrint() {
      return this.$emit('print', this.id)
    },
    emitScrollBottom() {
      if (!this.initialDisplayFlg && this.number === this.lastIndex) {
        this.$emit('scroll-bottom')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-table-body-row {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #{$light-grey};
  background-color: #{$white};
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &:last-child {
    height: 55px;
    border: none;
  }
  &:nth-child(even) {
    background-color: #{$white_f7};
  }
  > .id {
    display: none;
  }
}
</style>
