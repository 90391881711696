<template>
  <div class="show-table">
    <table>
      <tr v-for="datum in data" :key="datum.key">
        <th>{{ datum.key }}</th>
        <td data-e2e="show-table">{{ datum.value }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ShowTable',

  props: {
    data: { type: Array }
  }
}
</script>

<style lang="scss" scoped>
.show-table {
  > table {
    max-width: 600px;
    min-width: 450px;
    border-spacing: 0;
    > tr {
      height: 52px;
      > th {
        width: 40%;
        max-width: 240px;
        font-size: 15px;
        font-weight: normal;
      }
      > td {
        width: 60%;
        max-width: 320px;
        font-size: 13px;
        padding-top: 1px;
        word-wrap: break-word;
      }
    }
  }
}
</style>
