<template>
  <div class="reservation-request-show">
    <base-back-link class="base-back-link" @click="backReservationRequests" />
    <h2>予約申請詳細</h2>
    <div class="mail-infomation-area">
      <div class="mail-infomation">
        <div class="date" data-test="date">
          {{ reservationRequest.createdAt | dateFormatter }}
        </div>
      </div>
      <div class="button-wrapper">
        <base-button-small-orange
          class="create"
          v-if="lookOnlyFlg === 0"
          @click="newReservation"
          >予約作成</base-button-small-orange
        >
        <base-button-small-red
          class="delete"
          v-if="lookOnlyFlg === 0"
          @click="alertOpen"
          >削除</base-button-small-red
        >
      </div>
    </div>
    <div class="body">
      <show-table class="show-table" :data="showTableData" />
    </div>
    <announce-popup
      v-if="alertFlg"
      @cancel="alertClose"
      @decision="deleteReservationRequest"
      @close="closePopup"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import ShowTable from '@/components/parts/molecules/ShowTable'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ReservationRequestShow',

  components: {
    BaseBackLink,
    ShowTable,
    BaseButtonSmallRed,
    BaseButtonSmallOrange,
    AnnouncePopup
  },

  filters: {
    dateFormatter: date => {
      return moment(date).format('YYYY年 MM月 D日（dd）HH:mm') + ' 申請'
    }
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      reservationRequest: {},
      showTableData: [],
      alertFlg: false,
      waitFlg: false,
      title: '注意',
      buttons: ['削除しない', '削除する'],
      type: 'alert',
      popupMessage: '削除してもよろしいですか？',
      endTime: ''
    }
  },

  computed: {
    ...mapGetters({
      getReservationRequest: 'reservationRequests/getDataById',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getReservationPurpose: 'reservationPurposes/getDataById',
      getReservationColumn: 'reservationColumns/getDataById',
      getStaffReservationColumn: 'staffReservationColumns/getDataByColumnId',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  async created() {
    this.reservationRequest = this.getReservationRequest(this.id)
    this.showTableData = this.createShowTableData(this.reservationRequest)
    if (this.reservationRequest.readFlg === 0 && this.lookOnlyFlg === 0) {
      await this.$store.dispatch('reservationRequests/read', this.id)
    }
  },

  methods: {
    createShowTableData(reservationRequest) {
      const owner = this.getOwner(reservationRequest.ownerId)
      const patient = this.getPatient(reservationRequest.patientId)
      const minuteDiff = this.getReservationPurpose(
        this.reservationRequest.reservationPurposeId
      ).time
      const endTime = moment(reservationRequest.startTime, 'HHmm')
        .add(minuteDiff, 'minutes')
        .format('HH:mm')
      const reservationColumn = this.getReservationColumn(
        reservationRequest.reservationColumnId
      )
      return [
        { key: '飼主ID', value: owner.showId },
        { key: '飼主名', value: `${owner.lastName} ${owner.firstName}` },
        { key: '患者ID', value: patient.showId },
        { key: '患者名', value: patient.name },
        {
          key: '申請日',
          value: moment(reservationRequest.createdAt).format(
            'YYYY年MM月DD日（dd）'
          )
        },
        {
          key: '予約日',
          value: moment(reservationRequest.date).format('YYYY年MM月DD日（dd）')
        },
        {
          key: '診療時間',
          value:
            reservationRequest.startTime.slice(0, 2) +
            ':' +
            reservationRequest.startTime.slice(2, 4) +
            '～' +
            endTime
        },
        {
          key: '来院目的',
          value: this.getReservationPurpose(
            reservationRequest.reservationPurposeId
          ).name
        },
        {
          key: '予約列',
          value: reservationColumn.name
        },
        { key: '電話番号', value: owner.tel },
        { key: 'メールアドレス', value: owner.email }
      ]
    },
    backReservationRequests() {
      this.$router.push({ path: '/main/notifications/reservation-requests' })
    },
    async newReservation() {
      if (!this.existsReservationRequest()) {
        this.title = '注意'
        this.buttons = ['閉じる']
        this.type = 'alert'
        this.popupMessage = 'この予約申請は既に削除されています。'
        this.alertFlg = true
        return
      }
      const minuteDiff = this.getReservationPurpose(
        this.reservationRequest.reservationPurposeId
      ).time
      let endTime = moment(this.reservationRequest.startTime, 'HHmm')
        .add(minuteDiff, 'minutes')
        .format('HHmm')
      if (endTime < this.reservationRequest.startTime) endTime = '2355'
      const newReservation = {
        id: 0,
        date: this.reservationRequest.date,
        startTime: this.reservationRequest.startTime,
        endTime,
        reservationColumnId: this.reservationRequest.reservationColumnId,
        staffId: this.reservationRequest.staffId,
        reservationPurposeId: this.reservationRequest.reservationPurposeId,
        ownerId: this.reservationRequest.ownerId,
        patientId: this.reservationRequest.patientId,
        memo: this.reservationRequest.memo
      }
      const staffReservationColumn = this.getStaffReservationColumn(
        newReservation.reservationColumnId
      )
      this.$store.dispatch('timeTable/changeDate', this.reservationRequest.date)
      this.$store.dispatch(
        'timeTable/setReservationRequestId',
        this.reservationRequest.id
      )
      this.$store.dispatch('timeTable/setInitialReservation', newReservation)
      if (staffReservationColumn.showFlg === 0) {
        this.$store.dispatch(
          'staffReservationColumns/toggleShowFlg',
          staffReservationColumn.id
        )
      }
      this.$router.push({ path: '/main' })
    },
    alertOpen() {
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
      this.alertFlg = true
    },
    alertClose() {
      this.alertFlg = false
    },
    closePopup() {
      this.$router.push({ path: '/main/notifications/reservation-requests' })
    },
    async deleteReservationRequest() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'reservationRequests/deleteReservationRequest',
        this.id
      )
      if (res.result === 'success') {
        this.title = '完了'
        this.buttons = ['閉じる']
        this.type = 'success'
        this.popupMessage = res.sendMailFlg
          ? '削除しました。\n飼主様へメールを送信しました。'
          : '削除しました'
      } else {
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.type = 'failure'
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    },
    existsReservationRequest() {
      return this.getReservationRequest(this.id) ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-request-show {
  > .base-back-link {
    margin-top: -20px;
    margin-left: -25px;
  }
  > h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 24px;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
  }
  > .mail-infomation-area {
    margin-top: -10px;
    display: flex;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    > .mail-infomation {
      > .date {
        font-size: 15px;
        font-weight: bold;
      }
    }
    > .button-wrapper {
      margin-left: auto;
      > .create {
        float: left;
        margin: 20px;
      }
      > .delete {
        float: left;
        margin: 20px;
      }
    }
  }
  > .body {
    font-size: 15px;
    margin-top: 31px;
  }
}
</style>
