<template>
  <div class="notice-show">
    <base-back-link class="base-back-link" @click="backNotices" />
    <h2>{{ notice.title }}</h2>
    <div class="mail-infomation-area">
      <div class="mail-infomation" data-test="mail-information">
        {{ information }}
      </div>
      <base-button-small-red
        class="delete"
        @click="openAlert"
        v-if="lookOnlyFlg === 0"
        >削除</base-button-small-red
      >
    </div>
    <div class="body">
      <div class="text" data-test="message">
        <span>{{ message }}</span>
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      @cancel="closeAlert"
      @decision="deleteNotice"
      @close="closePopup"
      :disabled="waitFlg"
      :title="title"
      :buttons="buttons"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'NoticeShow',

  components: {
    BaseBackLink,
    BaseButtonSmallRed,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      notice: {},
      waitFlg: false,
      alertFlg: false,
      title: '注意',
      buttons: ['削除しない', '削除する'],
      type: 'alert',
      popupMessage: '削除してもよろしいですか？'
    }
  },

  computed: {
    ...mapGetters({
      getNotice: 'notices/getDataById',
      getMasterDatum: 'master/getDataById',
      getPatient: 'patients/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    information() {
      return `${moment(this.notice.createdAt).format(
        'YYYY年 MM月 DD日（dd）'
      )}\n${
        this.getMasterDatum('noticeCategories', this.notice.noticeCategoryId)
          .name
      }`
    },
    message() {
      if (this.notice.noticeCategoryId === 4) {
        // noticeCategoryId === 4 予防接種アラート
        let message = ''
        const patientVaccines = JSON.parse(this.notice.message).patientVaccines
        patientVaccines.forEach(patientVaccine => {
          const patient = this.getPatient(patientVaccine.patientId)
          const vaccineName = this.getMasterDatum(
            'vaccines',
            patientVaccine.vaccineId
          ).name
          const when =
            patientVaccine.alertBeforeDay === 0
              ? 'が今日となりました。'
              : patientVaccine.alertBeforeFlg === 1
              ? `まで後${patientVaccine.alertBeforeDay}日となりました。`
              : `から${patientVaccine.alertBeforeDay}日が経過しました。`
          message += `${patient.name}（患者ID ${patient.showId}）の${vaccineName}の接種期間開始${when}\n`
        })
        return message
      } else {
        return this.notice.message
      }
    }
  },

  async created() {
    this.notice = _.cloneDeep(this.getNotice(this.id))
    if (this.notice.readFlg === 0 && this.lookOnlyFlg === 0) {
      await this.$store.dispatch('notices/read', this.id)
    }
  },

  methods: {
    backNotices() {
      this.$router.push({ path: '/main/notifications/notices' })
    },
    openAlert() {
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
    },
    closePopup() {
      this.$router.push({ path: '/main/notifications/notices' })
    },
    async deleteNotice() {
      this.waitFlg = true
      const result = await this.$store.dispatch('notices/delete', this.id)
      if (result === 'success') {
        this.title = '完了'
        this.buttons = ['閉じる']
        this.type = 'success'
        this.popupMessage = '削除しました'
      } else {
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.type = 'failure'
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.notice-show {
  > .base-back-link {
    margin-top: -20px;
    margin-left: -25px;
  }
  > h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 24px;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
  }
  > .mail-infomation-area {
    margin-top: -10px;
    display: flex;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    > .mail-infomation {
      font-size: 15px;
      font-weight: bold;
      margin-top: 17px;
      white-space: pre-line;
    }
    > .delete {
      margin: 20px 0 0 auto;
    }
  }
  > .body {
    display: flex;
    font-size: 15px;
    margin-top: 31px;
    > .text {
      flex: 1;
      width: 0;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
}
</style>
