<template>
  <div class="list-table-read">
    <div class="table-number-button">
      <div class="table-number" data-test="table-number">
        {{ hitAllDataCounts }}件
      </div>
      <base-button-small-white
        class="button"
        v-if="allReadFlg && lookOnlyFlg === 0"
        @click="readAll"
        :disabled="checkReadFlg || allReadWaitFlg"
        >全て既読にする</base-button-small-white
      >
    </div>
    <div class="table-header">
      <list-table-header-row v-bind="listTableHeaderData" />
    </div>
    <recycle-scroller
      v-if="bodyData.length > 0"
      class="table-body"
      :items="bodyData"
      :item-size="56"
      key-field="id"
      v-slot="{ item, index }"
    >
      <list-table-body-row
        :intersectionObserverFlg="true"
        :initialDisplayFlg="initialDisplayFlg"
        :style="colorStyle(index)"
        :key="item.id"
        :rowItems="item.rowItems"
        :number="item.number"
        :lastIndex="bodyData.length - 1"
        @click.native="selectRow(item.id)"
        @scroll-bottom="$emit('scroll-bottom')"
      ></list-table-body-row>
    </recycle-scroller>
    <div v-else>対象のデータはありませんでした</div>
  </div>
</template>

<script>
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import ListTableHeaderRow from '@/components/parts/molecules/ListTableHeaderRow.vue'
import ListTableBodyRow from '@/components/parts/molecules/ListTableBodyRow.vue'
import { mapGetters } from 'vuex'
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  name: 'ListTableRead',

  components: {
    ListTableHeaderRow,
    ListTableBodyRow,
    BaseButtonSmallWhite,
    RecycleScroller
  },

  props: {
    initialDisplayFlg: { type: Boolean },
    headerData: { type: Array },
    hitAllDataCounts: { type: Number },
    bodyData: { type: Array },
    headerItemStyleData: {
      type: Array,
      default: function() {
        return []
      }
    },
    allReadFlg: { type: Boolean, default: false },
    allReadWaitFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      listTableHeaderData: {}
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    checkReadFlg() {
      const result = this.bodyData.find(v => v.rowItems[0].itemText === '●')
      return result === undefined ? true : false
    }
  },

  created() {
    this.listTableHeaderData = this.createListTableHeaderData()
  },

  methods: {
    createListTableHeaderData() {
      const headerData = this.headerData
      const headerItemStyleData = this.headerItemStyleData
      let rowItems = []
      for (let i = 0; i < headerData.length; i++) {
        let object = {
          itemId: i + 1,
          itemText: headerData[i],
          itemStyle: headerItemStyleData[i]
        }
        rowItems.push(object)
      }
      const tmpListTableHeaderData = {
        rowItems: rowItems
      }
      return tmpListTableHeaderData
    },
    selectRow(id) {
      this.$emit('click', id)
    },
    readAll() {
      const readIds = this.bodyData.map(v => v.id)
      this.$emit('read-all', readIds)
    },
    colorStyle(index) {
      return (index + 1) % 2 === 0
        ? {
            'background-color': '#f7f7f7',
            'border-bottom': '1px solid #cecece'
          }
        : {
            'background-color': '#ffffff',
            'border-bottom': '1px solid #cecece'
          }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-table-read {
  > .table-number-button {
    display: flex;
    align-items: center;
    height: 64px;
    padding-left: 20px;
    background-color: #{$white};
    border: solid #{$light-grey};
    border-width: 1px 1px 1px 1px;
    box-sizing: border-box;
    > .table-number {
      font-size: 15px;
    }
    > .button {
      display: flex;
      margin: 0 0 0 auto;
      padding-right: 20px;
    }
  }
  > .table-header {
    height: 40px;
    border: solid #{$light-grey};
    border-width: 0px 1px 1px 1px;
    box-sizing: border-box;
  }
  > .table-body {
    max-height: calc(100% - 104px);
    overflow-y: scroll;
    overflow-x: hidden;
    border: solid #{$light-grey};
    border-width: 0px 1px 0px 1px;
    box-sizing: border-box;
  }
}
</style>
