<template>
  <div class="reservation-cancels">
    <base-loading :waitFlg="waitFlg" />
    <p>絞り込み</p>
    <search-area
      :periodFlg="true"
      :periodLabel="'診療日'"
      :defaultStartDate="defaultStartDate"
      :defaultEndDate="defaultEndDate"
      :searchButtonFlg="true"
      :waitFlg="waitFlg"
      @input-start-date="inputDate($event, 'start')"
      @input-end-date="inputDate($event, 'end')"
      @clear="clearDate"
      @search="resetAndGetData"
    />
    <div class="search-order">
      <div class="order-title">予約キャンセル履歴一覧</div>
      <div class="data-change-message" v-if="dataChangeFlg">
        ※データの変更が検知されました。検索ボタンを押して更新して下さい。
      </div>
    </div>
    <list-table-read
      class="list-table"
      :initialDisplayFlg="initialDisplayFlg"
      :headerData="headers"
      :hitAllDataCounts="hitAllDataCounts"
      :bodyData="bodyData"
      :headerItemStyleData="styles"
      :allReadFlg="true"
      :allReadWaitFlg="waitFlg"
      @click="pushToReservationCancelShow"
      @read-all="readAll"
      @scroll-bottom="getNextData"
    />
    <announce-popup
      v-if="popupFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import SearchArea from '@/components/parts/molecules/SearchArea'
import ListTableRead from '@/components/parts/organisms/ListTableRead.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { createDisplayBodyData } from '@/utils/list_table_read'
import BaseLoading from '@/components/parts/atoms/BaseLoading'

export default {
  name: 'ReservationCancels',

  components: {
    SearchArea,
    ListTableRead,
    AnnouncePopup,
    BaseLoading
  },

  data() {
    return {
      bodyData: [],
      initialDisplayFlg: true,
      startDate: '',
      defaultStartDate: '',
      endDate: '',
      defaultEndDate: '',
      sortType: 2,
      headers: [
        '',
        '',
        '飼主ID',
        '飼主名',
        'キャンセル日[経過日数]',
        '診療日',
        '診療内容',
        '電話番号'
      ],
      properties: [
        'read',
        'originCancelDatetime',
        'ownerId',
        'ownerName',
        'cancelDate',
        'medicalCareDate',
        'care',
        'tel'
      ],
      styles: [
        {
          width: '25px',
          color: '#ffa62b',
          fontSize: '8px',
          overflow: 'visible',
          padding: '0'
        },
        { width: '0%', display: 'none' },
        { width: '17%' },
        { width: '17%' },
        { width: '17%' },
        { width: '16%' },
        { width: '16%' },
        { width: '16%' }
      ],
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      waitFlg: false,
      page: 0,
      isFull: true,
      hitAllDataCounts: 0,
      openDisplayTime: '',
      dataChangeFlg: false,
      judgedReadReservationIds: new Set(),
      judgedCancelReservationIds: new Set(),
      judgedDelReservationIds: new Set(),
      unsubscribe: null
    }
  },

  computed: {
    ...mapGetters({
      reservations: 'reservations/getCancelReservations',
      getOwner: 'owners/getDataById'
    })
  },

  created() {
    const startMoment = moment().subtract(7, 'd')
    this.startDate = startMoment.format('YYYYMMDD')
    this.defaultStartDate = startMoment.toDate()
    const endMoment = moment()
    this.endDate = endMoment.format('YYYYMMDD')
    this.defaultEndDate = endMoment.toDate()
    this.bodyData = this.createInitialBodyData()
    this.hitAllDataCounts = this.bodyData.length
    this.openDisplayTime = moment()
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'reservations/updateAfter') {
        mutation.payload.forEach(reservation => {
          if (
            reservation.cancelFlg === 1 &&
            reservation.cancelReadFlg === 1 &&
            reservation.delFlg === 0 &&
            moment(reservation.updatedAt).isSameOrAfter(this.openDisplayTime) &&
            !this.judgedReadReservationIds.has(reservation.id)
          ) {
            //既読にしたデータの場合
            this.judgedReadReservationIds.add(reservation.id)
            const changeDataIndex = this.bodyData.findIndex(
              v => v.id === reservation.id
            )
            if (changeDataIndex !== -1) {
              const changeDatum = createDisplayBodyData(
                [
                  {
                    ...this.makeDisplayDatum(reservation),
                    number: this.bodyData[changeDataIndex].number
                  }
                ],
                this.properties,
                this.styles,
                this.initialDisplayFlg,
                this.sortType
              )[0]
              this.$set(this.bodyData, changeDataIndex, changeDatum)
            }
          } else {
            const isCanceledNotReadDatum =
              reservation.cancelFlg === 1 &&
              reservation.cancelReadFlg === 0 &&
              reservation.delFlg === 0 &&
              moment(reservation.updatedAt).isSameOrAfter(
                this.openDisplayTime
              ) &&
              !this.judgedCancelReservationIds.has(reservation.id)
            const isCanceledDeletedDatum =
              reservation.cancelFlg === 1 &&
              reservation.delFlg === 1 &&
              moment(reservation.updatedAt).isSameOrAfter(
                this.openDisplayTime
              ) &&
              !this.judgedDelReservationIds.has(reservation.id)
            if (isCanceledNotReadDatum || isCanceledDeletedDatum) {
              //キャンセル（未読）または削除したデータで、かつ、socket通信で初めて送られてきたデータの場合。
              //socket通信では1つ前に操作した時のデータも一緒に入っているので、そのデータは判定の対象にしないようにする。
              isCanceledNotReadDatum
                ? this.judgedCancelReservationIds.add(reservation.id)
                : this.judgedDelReservationIds.add(reservation.id)
              if (
                this.filterByDate(reservation.date) ||
                this.bodyData.find(v => v.id === reservation.id)
              ) {
                //検索条件に一致している、または画面内にデータが表示されている場合、更新を促すメッセージを表示
                this.dataChangeFlg = true
              }
            }
          }
        })
      }
    })
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  methods: {
    filterByDate(date) {
      return this.startDate !== '' && this.endDate !== ''
        ? this.startDate <= date && date <= this.endDate
        : this.startDate !== ''
        ? this.startDate <= date
        : this.endDate !== ''
        ? date <= this.endDate
        : true
    },
    makeDisplayDatum(reservation) {
      const owner = this.getOwner(reservation.ownerId)
      const today = moment().startOf('days')
      const cancelDate = moment(
        reservation.cancelDatetime,
        'YYYYMMDDHHmmss'
      ).startOf('days')
      return {
        id: reservation.id,
        read: reservation.cancelReadFlg ? '' : '●',
        originCancelDatetime: reservation.cancelDatetime,
        ownerId: owner !== undefined ? owner.showId : '',
        ownerName:
          owner !== undefined
            ? `${owner.lastName} ${owner.firstName}`
            : `${reservation.ownerLastName} ${reservation.ownerFirstName}`,
        cancelDate: `${cancelDate.format('YYYY年MM月DD日')}（${today.diff(
          cancelDate,
          'days'
        )}日）`,
        medicalCareDate: moment(reservation.date).format('YYYY年MM月DD日'),
        care: reservation.care,
        tel: owner !== undefined ? owner.tel : ''
      }
    },
    createInitialBodyData() {
      const tmpBodyData = this.reservations.flatMap(reservation => {
        return this.filterByDate(reservation.date)
          ? { ...this.makeDisplayDatum(reservation), number: 0 }
          : []
      })
      return createDisplayBodyData(
        tmpBodyData,
        this.properties,
        this.styles,
        this.initialDisplayFlg,
        this.sortType
      )
    },
    createAddBodyData(resReservations) {
      const lastKey = this.bodyData.length
      const tmpAddBodyData = resReservations.map((reservation, index) => {
        return {
          ...this.makeDisplayDatum(reservation),
          number: lastKey + index
        }
      })
      return createDisplayBodyData(
        tmpAddBodyData,
        this.properties,
        this.styles,
        this.initialDisplayFlg,
        this.sortType
      )
    },
    async resetAndGetData() {
      this.bodyData = []
      this.page = 0
      this.isFull = false
      this.dataChangeFlg = false
      await this.searchCancelReservations()
    },
    async getNextData() {
      if (!this.isFull && !this.waitFlg) await this.searchCancelReservations()
    },
    async searchCancelReservations() {
      if (this.initialDisplayFlg) this.initialDisplayFlg = false
      this.waitFlg = true
      const res = await this.$store.dispatch('reservations/search', {
        startDate: this.startDate,
        endDate: this.endDate,
        page: this.page
      })
      if (res.result === true) {
        this.page++
        this.hitAllDataCounts = res.hitAllDataCounts
        this.bodyData = this.bodyData.concat(
          this.createAddBodyData(res.reservations)
        )
        if (this.bodyData.length < 100 || res.reservations.length === 0) {
          this.isFull = true
        }
        if (this.page >= 10 && this.bodyData.length >= 1000) {
          this.isFull = true
          this.popupFlg = true
          this.type = 'alert'
          this.title = '警告'
          this.buttons = ['閉じる']
          this.popupMessage =
            '予約キャンセルの表示件数が上限の1000件に達しました。'
        }
      } else {
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '通信エラーが発生しました。'
      }
      this.waitFlg = false
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    async readAll(readIds) {
      this.waitFlg = true
      const result = await this.$store.dispatch('reservations/readAll', readIds)
      if (result) {
        this.bodyData = this.bodyData.map(v => {
          if (readIds.includes(v.id)) {
            v.rowItems[0].itemText = ''
            return v
          } else {
            return v
          }
        })
      } else {
        this.popupFlg = true
        this.type = 'alert'
        this.title = 'お知らせ'
        this.buttons = ['閉じる']
        this.popupMessage = 'データが変更された可能性があります。'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.buttons = []
      this.popupMessage = ''
    },
    pushToReservationCancelShow(id) {
      this.$router.push({
        path: `/main/notifications/reservation-cancels/${id}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-cancels {
  > p {
    font-size: 15px;
    font-weight: bold;
  }
  > .search-order {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 33px;
    display: flex;
    align-items: center;
    > .order-title {
      font-size: 15px;
      font-weight: bold;
    }
    > .data-change-message {
      margin-left: 20px;
      margin-top: 3px;
      font-size: 13px;
      font-weight: bold;
      color: #{$tomato};
    }
  }
  > .list-table {
    height: calc(100vh - 400px);
    max-height: 664px; // 104px（件数ヘッダー部分の高さ）+ 56px * n（列の高さ×列数）
  }
}
</style>
