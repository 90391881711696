<template>
  <div class="received-mail-show">
    <base-back-link class="base-back-link" @click="backRecievedMails" />
    <h2>受信メール詳細</h2>
    <div class="mail-infomation-area">
      <div class="mail-infomation">
        <div class="date" data-test="date">
          {{ information }}
        </div>
        <br />
        <show-table class="show-table" :data="showTableData" />
      </div>
      <base-button-small-red
        class="delete"
        v-if="lookOnlyFlg === 0"
        @click="alertOpen"
        >削除</base-button-small-red
      >
      <base-button-small-white
        class="reservation"
        v-if="reservation"
        @click="openReservation"
        >予約</base-button-small-white
      >
    </div>
    <div class="body">
      <div class="text">
        <span>{{ receivedMail.message }}</span>
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      @cancel="alertClose"
      @decision="deleteReceivedMail"
      @close="closePopup"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import ShowTable from '@/components/parts/molecules/ShowTable'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'ReceivedMailShow',

  components: {
    BaseBackLink,
    ShowTable,
    BaseButtonSmallRed,
    AnnouncePopup,
    BaseButtonSmallWhite
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      receivedMail: {},
      showTableData: [],
      waitFlg: false,
      alertFlg: false,
      title: '注意',
      buttons: ['削除しない', '削除する'],
      type: 'alert',
      popupMessage: '削除してもよろしいですか？'
    }
  },

  computed: {
    ...mapGetters({
      getReceivedMail: 'receivedMails/getDataById',
      getReservation: 'reservations/getDataById',
      getOwner: 'owners/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    information() {
      return `${moment(this.receivedMail.date).format(
        'YYYY年 MM月 DD日（dd）HH:mm'
      )}`
    },
    reservation() {
      let reservation = null
      this.receivedMail.reservationIds.some(rId => {
        const r = this.getReservation(rId)
        if (r?.cancelFlg === 0 && r?.delFlg === 0) {
          reservation = r
          return true
        }
      })
      if (!reservation) {
        this.receivedMail.reservationIds.some(rId => {
          const r = this.getReservation(rId)
          if (r?.cancelFlg === 1 && r?.delFlg === 0) {
            reservation = r
            return true
          }
        })
      }
      return reservation
    }
  },

  async created() {
    this.receivedMail = _.cloneDeep(this.getReceivedMail(this.id))
    this.showTableData = this.createShowTableData(this.receivedMail.ownerId)
    if (this.receivedMail.readFlg === 0 && this.lookOnlyFlg === 0) {
      await this.$store.dispatch('receivedMails/read', this.id)
    }
  },

  methods: {
    createShowTableData(ownerId) {
      const owner = this.getOwner(ownerId)
      return [
        { key: '飼主ID', value: owner.showId },
        { key: '飼主名', value: `${owner.lastName} ${owner.firstName}` },
        { key: 'メールアドレス', value: owner.email }
      ]
    },
    backRecievedMails() {
      this.$router.push({ path: '/main/notifications/received-mails' })
    },
    alertOpen() {
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
      this.alertFlg = true
    },
    alertClose() {
      this.alertFlg = false
    },
    closePopup() {
      this.$router.push({ path: '/main/notifications/received-mails' })
    },
    async deleteReceivedMail() {
      this.waitFlg = true
      const result = await this.$store.dispatch('receivedMails/delete', this.id)
      if (result === true) {
        this.title = '完了'
        this.buttons = ['閉じる']
        this.type = 'success'
        this.popupMessage = '削除しました'
      } else {
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.type = 'failure'
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    },
    openReservation() {
      if (this.reservation.cancelFlg === 1) {
        this.$router.push({
          path: `/main/notifications/reservation-cancels/${this.reservation.id}`
        })
      } else {
        this.$store.dispatch('timeTable/setInitialReservation', {
          ...this.reservation
        })
        this.$store.dispatch('timeTable/changeDate', this.reservation.date)
        this.$store.dispatch('timeTable/showPopup')
        this.$router.push({ path: '/main' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.received-mail-show {
  > .base-back-link {
    margin-top: -20px;
    margin-left: -25px;
  }
  > h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 24px;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
  }
  > .mail-infomation-area {
    margin-top: -10px;
    display: flex;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    > .mail-infomation {
      > .date {
        font-size: 15px;
        font-weight: bold;
      }
    }
    > .delete {
      margin: 20px 20px 0 auto;
      display: block;
    }
    > .reservation {
      margin-top: 20px;
    }
  }
  > .body {
    display: flex;
    font-size: 15px;
    margin-top: 31px;
    > .text {
      flex: 1;
      width: 0;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
}
</style>
