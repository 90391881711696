<template>
  <div class="list-table-header-row" :style="headerRowStyles">
    <list-table-row-item
      v-for="item in rowItems"
      :key="item.itemId"
      v-bind="item"
      class="row-item"
    />
  </div>
</template>

<script>
import ListTableRowItem from '@/components/parts/atoms/ListTableRowItem.vue'

export default {
  name: 'ListTableHeaderRow',

  components: {
    ListTableRowItem
  },

  props: {
    rowItems: { type: Array },
    headerRowStyles: { type: Object }
  }
}
</script>

<style lang="scss" scoped>
.list-table-header-row {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #{$pale};
  padding-right: 17px;
  > .row-item {
    line-height: 40px;
    border-right: 1px solid #{$skin};
    font-feature-settings: 'palt';
    &:last-child {
      border: none;
    }
  }
}
</style>
