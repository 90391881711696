const createObjects = (bodyData, properties) => {
  let objects = []
  for (let i = 0; i < bodyData.length; i++) {
    let object = {}
    object['primitiveId'] = bodyData[i].id
    object['number'] = bodyData[i].number
    for (let j = 0; j < properties.length; j++) {
      object[properties[j]] = bodyData[i][properties[j]]
    }
    objects.push(object)
  }
  return objects
}

const sortDisplayBodyData = (displayBodyData, sortType) => {
  return displayBodyData.sort((a, b) => {
    if (a.rowItems[sortType - 1].itemText < b.rowItems[sortType - 1].itemText) {
      return 1
    } else if (
      a.rowItems[sortType - 1].itemText === b.rowItems[sortType - 1].itemText
    ) {
      a.id < b.id ? 1 : -1
    } else {
      return -1
    }
  })
}

export const createDisplayBodyData = (
  bodyData,
  properties,
  styles,
  initialDisplayFlg,
  sortType
) => {
  const objectEntries = createObjects(bodyData, properties).map(v =>
    Object.entries(v)
  )
  const displayBodyData = []
  for (let i = 0; i < objectEntries.length; i++) {
    const primitiveId = objectEntries[i][0][1]
    const number = objectEntries[i][1][1]
    let rowItems = []
    objectEntries[i].splice(0, 2) //画面に表示しない値(primitiveIdとnumber)を削除
    for (let j = 0; j < objectEntries[i].length; j++) {
      let object = {
        itemId: j + 1,
        itemKey: objectEntries[i][j][0],
        itemText: objectEntries[i][j][1],
        itemStyle: styles[j]
      }
      rowItems.push(object)
    }
    displayBodyData.push({
      id: primitiveId,
      rowItems: rowItems,
      number
    })
  }
  return initialDisplayFlg
    ? sortDisplayBodyData(displayBodyData, sortType)
    : displayBodyData
}
