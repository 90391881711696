<template>
  <div class="send-mails">
    <base-loading :waitFlg="waitFlg" />
    <p>絞り込み</p>
    <search-area
      :periodLabel="'送信日'"
      :periodFlg="true"
      :fifthFlg="true"
      :selectBoxLabel5="'表示'"
      :selectValue5="categoryValue"
      :selectBoxData5="categoryData"
      :searchButtonFlg="true"
      :defaultStartDate="defaultStartDate"
      :defaultEndDate="defaultEndDate"
      :waitFlg="waitFlg"
      @input-start-date="inputDate($event, 'start')"
      @input-end-date="inputDate($event, 'end')"
      @clear="clearDate"
      @select5="changeCategoryValue"
      @search="resetAndGetData"
    />
    <div class="search-order">
      <div class="order-title">送信メール一覧</div>
      <div class="data-change-message" v-if="dataChangeFlg">
        ※データの変更が検知されました。検索ボタンを押して更新して下さい。
      </div>
      <div class="order-setting">
        <div class="label">並び替え：</div>
        <base-select-box
          class="select-box"
          :selectData="sortTypes"
          v-model="sortType"
        />
        <base-select-box
          class="select-box"
          :selectData="orderTypes"
          v-model="orderType"
        />
      </div>
    </div>
    <list-table-read
      class="list-table"
      :initialDisplayFlg="initialDisplayFlg"
      :headerData="headers"
      :headerItemStyleData="styles"
      :hitAllDataCounts="hitAllDataCounts"
      :bodyData="bodyData"
      @click="pushToSendMailShow"
      @scroll-bottom="getNextData"
    />
    <announce-popup
      v-if="popupFlg"
      @close="closePopup"
      :title="title"
      :buttons="buttons"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import SearchArea from '@/components/parts/molecules/SearchArea'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import ListTableRead from '@/components/parts/organisms/ListTableRead.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { createDisplayBodyData } from '@/utils/list_table_read'
import BaseLoading from '@/components/parts/atoms/BaseLoading'

export default {
  name: 'SendMails',

  components: {
    BaseLoading,
    SearchArea,
    BaseSelectBox,
    ListTableRead,
    AnnouncePopup
  },

  data() {
    return {
      bodyData: [],
      addBodyData: [],
      initialDisplayFlg: true,
      startDate: '',
      defaultStartDate: '',
      endDate: '',
      defaultEndDate: '',
      sortType: 2,
      sortTypes: [
        { id: 2, name: '送信日時' },
        { id: 4, name: '飼主ID' },
        { id: 5, name: '飼主名' },
        { id: 6, name: 'メールアドレス' },
        { id: 7, name: 'カテゴリ' }
      ],
      orderType: 1,
      orderTypes: [
        { id: 0, name: '昇順' },
        { id: 1, name: '降順' }
      ],
      categoryValue: 1,
      categoryData: [
        { id: 1, name: 'すべて' },
        { id: 2, name: '手動メール' },
        { id: 3, name: '予約登録・変更' },
        { id: 4, name: '予約キャンセル' },
        { id: 5, name: '申請削除' },
        { id: 6, name: '前日メール' },
        { id: 7, name: '予防接種' }
      ],
      categoryNames: {
        manual: '手動メール',
        reservation: '予約登録・変更',
        cancelReservation: '予約キャンセル',
        deleteRequest: '申請削除',
        previous: '前日メール',
        vaccination: '予防接種'
      },
      headers: [
        '',
        '送信日時',
        '飼主ID',
        '飼主名',
        'メールアドレス',
        'カテゴリ'
      ],
      properties: [
        'originDate',
        'date',
        'ownerId',
        'ownerName',
        'email',
        'category'
      ],
      styles: [
        { width: '0%', display: 'none' },
        { width: '22%' },
        { width: '17%' },
        { width: '22%' },
        { width: '22%' },
        { width: '17%' }
      ],
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      waitFlg: false,
      page: 0,
      isFull: true,
      hitAllDataCounts: 0,
      lastUpdated: '',
      dataChangeFlg: false,
      unsubscribe: null
    }
  },

  computed: {
    ...mapGetters({
      sendMails: 'sendMails/getData',
      getOwner: 'owners/getDataById'
    })
  },

  watch: {
    sortType() {
      this.resetAndGetData()
    },
    orderType() {
      this.resetAndGetData()
    }
  },

  created() {
    const startMoment = moment().subtract(7, 'd')
    this.startDate = startMoment.format('YYYYMMDD')
    this.defaultStartDate = startMoment.toDate()
    const endMoment = moment()
    this.endDate = endMoment.format('YYYYMMDD')
    this.defaultEndDate = endMoment.toDate()
    this.bodyData = this.makeInitialBodyData()
    this.hitAllDataCounts = this.bodyData.length
    this.lastUpdated = moment()
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (
        mutation.type === 'sendMails/updateAfter' &&
        mutation.payload.some(sendMail => {
          const isDeletedDatum =
            sendMail.delFlg === 1 &&
            moment(sendMail.updatedAt).isSameOrAfter(this.lastUpdated)
          const isNewDatum = moment(sendMail.createdAt).isSameOrAfter(
            this.lastUpdated
          )
          if (isDeletedDatum || isNewDatum) {
            // 最終更新日時より後に作られた新規データまたは削除データを判定の対象にする。
            if (
              (this.filterByDate(
                moment(sendMail.sendDate).format('YYYYMMDD')
              ) &&
                this.filterByCategory(sendMail)) ||
              this.bodyData.find(v => v.id === sendMail.id)
            ) {
              //検索条件に一致している、または画面内にデータが表示されている場合、更新を促すメッセージを表示
              return true
            } else {
              return false
            }
          } else {
            false
          }
        })
      ) {
        this.dataChangeFlg = true
      }
    })
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  methods: {
    filterByDate(date) {
      return this.startDate !== '' && this.endDate !== ''
        ? this.startDate <= date && date <= this.endDate
        : this.startDate !== ''
        ? this.startDate <= date
        : this.endDate !== ''
        ? date <= this.endDate
        : true
    },
    filterByCategory(sendMail) {
      return this.categoryValue === 1
        ? true
        : this.categoryValue === 2 && sendMail.category === 'manual'
        ? true
        : this.categoryValue === 3 && sendMail.category === 'reservation'
        ? true
        : this.categoryValue === 4 && sendMail.category === 'cancelReservation'
        ? true
        : this.categoryValue === 5 && sendMail.category === 'deleteRequest'
        ? true
        : this.categoryValue === 6 && sendMail.category === 'previous'
        ? true
        : this.categoryValue === 7 && sendMail.category === 'vaccination'
        ? true
        : false
    },
    makeDisplayDatum(sm) {
      const owner = this.getOwner(sm.ownerIds[0])
      const ownerName =
        owner && sm.ownerIds.length > 1
          ? `${owner.lastName} ${owner.firstName} 他${sm.ownerIds.length - 1}名`
          : owner
          ? `${owner.lastName} ${owner.firstName}`
          : ''
      return {
        id: sm.id,
        originDate: sm.sendDate,
        date: moment(sm.sendDate).format('YYYY年MM月DD日（dd）HH:mm'),
        ownerId: owner !== undefined ? owner.showId : '',
        ownerName,
        email: owner.email,
        category: this.categoryNames[sm.category]
      }
    },
    makeInitialBodyData() {
      const tmpBodyData = this.sendMails.flatMap(sm => {
        const formatDate = moment(sm.sendDate).format('YYYYMMDD')
        return this.startDate <= formatDate && formatDate <= this.endDate
          ? { ...this.makeDisplayDatum(sm), number: 0 }
          : []
      })
      return createDisplayBodyData(
        tmpBodyData,
        this.properties,
        this.styles,
        this.initialDisplayFlg,
        this.sortType
      )
    },
    createAddBodyData(resSendMails) {
      const lastKey = this.bodyData.length
      const tmpAddBodyData = resSendMails.map((sm, i) => {
        return { ...this.makeDisplayDatum(sm), number: lastKey + i }
      })
      return createDisplayBodyData(
        tmpAddBodyData,
        this.properties,
        this.styles,
        this.initialDisplayFlg,
        this.sortType
      )
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    changeCategoryValue(id) {
      this.categoryValue = id
    },
    pushToSendMailShow(id) {
      this.$router.push({ path: `/main/notifications/send-mails/${id}` })
    },
    async resetAndGetData() {
      this.bodyData = []
      this.page = 0
      this.isFull = false
      this.dataChangeFlg = false
      await this.search()
    },
    async getNextData() {
      if (!this.isFull && !this.waitFlg) await this.search()
    },
    async search() {
      if (this.initialDisplayFlg) this.initialDisplayFlg = false
      this.waitFlg = true
      const res = await this.$store.dispatch('sendMails/search', {
        startDate: this.startDate,
        endDate: this.endDate,
        sortType:
          this.sortType === 2
            ? 'sendDate'
            : this.sortType === 4
            ? 'ownerShowId'
            : this.sortType === 5
            ? 'ownerName'
            : this.sortType === 6
            ? 'email'
            : 'category',
        order: this.orderType === 1 ? 'DESC' : 'ASC',
        filter:
          this.categoryValue === 1
            ? 'all'
            : this.categoryValue === 2
            ? 'manual'
            : this.categoryValue === 3
            ? 'reservation'
            : this.categoryValue === 4
            ? 'cancelReservation'
            : this.categoryValue === 5
            ? 'deleteRequest'
            : this.categoryValue === 6
            ? 'previous'
            : 'vaccination',
        page: this.page
      })
      if (res.result === 'success') {
        if (this.page === 0) this.hitAllDataCounts = res.hitAllDataCounts
        this.page++
        this.bodyData = this.bodyData.concat(
          this.createAddBodyData(res.sendMails)
        )
        if (this.bodyData.length < 100 || res.sendMails.length === 0) {
          this.isFull = true
        }
        if (this.page >= 10 && this.bodyData.length >= 1000) {
          this.isFull = true
          this.popupFlg = true
          this.type = 'alert'
          this.title = '警告'
          this.buttons = ['閉じる']
          this.popupMessage = 'メールの表示件数が上限の1000件に達しました。'
        }
        // リアクティブ対応の為、検索した後最終更新日時を更新する。
        this.lastUpdated = moment()
      } else {
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '通信エラーが発生しました。'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.buttons = []
      this.popupMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.send-mails {
  > p {
    font-size: 15px;
    font-weight: bold;
  }
  > .search-order {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-size: 13px;
    > .order-title {
      font-size: 15px;
      font-weight: bold;
    }
    > .data-change-message {
      margin-left: 20px;
      margin-top: 3px;
      font-size: 13px;
      font-weight: bold;
      color: #{$tomato};
    }
    > .order-setting {
      display: flex;
      align-items: center;
      margin-left: auto;
      > .select-box {
        margin-left: 10px;
      }
    }
  }
  > .list-table {
    height: calc(100vh - 400px);
    max-height: 664px; // 104px（件数ヘッダー部分の高さ）+ 56px * n（列の高さ×列数）
  }
}
</style>
