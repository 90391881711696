<template>
  <div class="send-mail-show">
    <base-back-link class="base-back-link" @click="backSendMails" />
    <h2>送信メール詳細</h2>
    <div class="mail-info-area">
      <div class="mail-info">
        <div class="date" data-test="date">
          {{ sendMail.sendDate | dateFormatter }}
        </div>
        <br />
        <div class="owner-info-area">
          <div
            v-for="(owner, index) in showTableData"
            :key="index"
            class="owner-info"
            data-test="owner-info"
          >
            <span>{{ owner.id }}</span>
            <span class="owner-name">{{ owner.name }}</span>
            <span>({{ owner.email }})</span>
          </div>
        </div>
      </div>
      <base-button-small-red
        class="delete"
        v-if="lookOnlyFlg === 0"
        @click="alertOpen"
        >削除</base-button-small-red
      >
    </div>
    <div class="body">
      <div class="text">
        <span>{{ sendMail.message }}</span>
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      @cancel="alertClose"
      @decision="deleteSendMail"
      @close="closePopup"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'sendMailShow',

  components: {
    BaseBackLink,
    BaseButtonSmallRed,
    AnnouncePopup
  },

  filters: {
    dateFormatter: date => {
      return moment(date).format('YYYY年 MM月 DD日（dd）HH:mm')
    }
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      sendMail: {},
      showTableData: [],
      alertFlg: false,
      waitFlg: false,
      title: '注意',
      buttons: ['削除しない', '削除する'],
      type: 'alert',
      popupMessage: '削除してもよろしいですか？'
    }
  },

  computed: {
    ...mapGetters({
      getSendMail: 'sendMails/getDataById',
      getOwner: 'owners/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.sendMail = this.getSendMail(this.id)
    this.showTableData = this.createShowTableData(this.sendMail.ownerIds)
  },

  methods: {
    createShowTableData(ownerIds) {
      return ownerIds.map(ownerId => {
        const owner = this.getOwner(ownerId)
        return {
          id: owner.showId,
          name: `${owner.lastName} ${owner.firstName}`,
          email: owner.email
        }
      })
    },
    backSendMails() {
      this.$router.push({ path: '/main/notifications/send-mails' })
    },
    alertOpen() {
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
      this.alertFlg = true
    },
    alertClose() {
      this.alertFlg = false
    },
    closePopup() {
      this.$router.push({ path: '/main/notifications/send-mails' })
    },
    async deleteSendMail() {
      this.waitFlg = true
      const result = await this.$store.dispatch('sendMails/delete', this.id)
      if (result === true) {
        this.title = '完了'
        this.buttons = ['閉じる']
        this.type = 'success'
        this.popupMessage = '削除しました'
      } else {
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.type = 'failure'
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.send-mail-show {
  > .base-back-link {
    margin-top: -20px;
    margin-left: -25px;
  }
  > h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 24px;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
  }
  > .mail-info-area {
    margin-top: -10px;
    display: flex;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    > .mail-info {
      > .date {
        font-size: 15px;
        font-weight: bold;
      }
      > .owner-info-area {
        max-height: 150px;
        overflow-y: auto;
        > .owner-info {
          > .owner-name {
            margin: 0 10px;
          }
        }
      }
    }
    > .delete {
      margin: 20px 0 0 auto;
    }
  }
  > .body {
    display: flex;
    font-size: 15px;
    margin-top: 31px;
    > .text {
      flex: 1;
      width: 0;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
}
</style>
