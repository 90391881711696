<template>
  <div class="reservation-cancel-show">
    <base-back-link class="base-back-link" @click="backReservationCancels" />
    <h2>予約キャンセル詳細</h2>
    <div class="mail-infomation-area">
      <base-button-small-red
        class="delete"
        v-if="lookOnlyFlg === 0"
        @click="alertOpen"
        >削除</base-button-small-red
      >
    </div>
    <div class="body">
      <show-table class="show-table" :data="showTableData" />
    </div>
    <announce-popup
      v-if="alertFlg"
      @cancel="alertClose"
      @decision="deleteReservation"
      @close="closePopup"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import ShowTable from '@/components/parts/molecules/ShowTable'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ReservationCancelShow',

  components: {
    BaseBackLink,
    ShowTable,
    BaseButtonSmallRed,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      reservation: {},
      showTableData: [],
      alertFlg: false,
      waitFlg: false,
      title: '注意',
      buttons: ['削除しない', '削除する'],
      type: 'alert',
      popupMessage: '削除してもよろしいですか？'
    }
  },

  computed: {
    ...mapGetters({
      getReservation: 'reservations/getDataById',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  async created() {
    this.reservation = this.getReservation(this.id)
    this.showTableData = this.createShowTableData(this.reservation)
    if (this.reservation.cancelReadFlg === 0 && this.lookOnlyFlg === 0) {
      await this.$store.dispatch('reservations/read', this.id)
    }
  },

  methods: {
    createShowTableData(reservation) {
      const owner = this.getOwner(reservation.ownerId)
      const patient = this.getPatient(reservation.patientId)
      const today = moment().startOf('day')
      const cancelDate = moment(reservation.cancelDatetime, 'YYYYMMDD')
      return [
        { key: '飼主ID', value: owner !== undefined ? owner.showId : '' },
        {
          key: '飼主名',
          value:
            owner !== undefined ? owner.lastName : reservation.ownerLastName
        },
        {
          key: '患者ID',
          value: patient !== undefined ? patient.showId : ''
        },
        {
          key: '患者名',
          value: patient !== undefined ? patient.name : reservation.patientName
        },
        {
          key: 'キャンセル日[経過日数]',
          value: `${cancelDate.format('YYYY年MM月DD日')}（${today.diff(
            cancelDate,
            'days'
          )}日）`
        },
        {
          key: '診療日',
          value: moment(reservation.date, 'YYYYMMDD').format('YYYY年MM月DD日')
        },
        {
          key: '診療内容',
          value: reservation.care
        },
        { key: '電話番号', value: owner !== undefined ? owner.tel : '' },
        {
          key: 'メールアドレス',
          value: owner !== undefined ? owner.email : ''
        }
      ]
    },
    backReservationCancels() {
      this.$router.push({ path: '/main/notifications/reservation-cancels' })
    },
    alertOpen() {
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
      this.alertFlg = true
    },
    alertClose() {
      this.alertFlg = false
    },
    closePopup() {
      this.$router.push({ path: '/main/notifications/reservation-cancels' })
    },
    async deleteReservation() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'reservations/delete',
        this.reservation
      )
      if (result === true) {
        this.title = '完了'
        this.buttons = ['閉じる']
        this.type = 'success'
        this.popupMessage = '削除しました'
      } else {
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.type = 'failure'
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-cancel-show {
  > .base-back-link {
    margin-top: -20px;
    margin-left: -25px;
  }
  > h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 24px;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
  }
  > .mail-infomation-area {
    margin-top: -10px;
    display: flex;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    > .mail-infomation {
      > .date {
        font-size: 15px;
        font-weight: bold;
      }
    }
    > .delete {
      margin: 20px 0 0 auto;
    }
  }
  > .body {
    font-size: 15px;
    margin-top: 31px;
  }
}
</style>
