<template>
  <div class="received-mails">
    <base-loading :waitFlg="waitFlg" />
    <p>絞り込み</p>
    <search-area
      :periodLabel="'受信日'"
      :periodFlg="true"
      :radioButtonLabel="'表示'"
      :radioButtonValue="radioButtonValue"
      :radioButtonData="radioButtonData"
      :searchButtonFlg="true"
      :defaultStartDate="defaultStartDate"
      :defaultEndDate="defaultEndDate"
      :waitFlg="waitFlg"
      @input-start-date="inputDate($event, 'start')"
      @input-end-date="inputDate($event, 'end')"
      @clear="clearDate"
      @select-radio-data="changeRadioButtonValue"
      @search="resetAndGetData"
    />
    <div class="search-order">
      <div class="order-title">受信メール一覧</div>
      <div class="data-change-message" v-if="dataChangeFlg">
        ※データの変更が検知されました。検索ボタンを押して更新して下さい。
      </div>
      <div class="order-setting">
        <div class="label">並び替え：</div>
        <base-select-box
          class="select-box"
          :selectData="sortTypes"
          v-model="sortType"
        />
        <base-select-box
          class="select-box"
          :selectData="orderTypes"
          v-model="orderType"
        />
      </div>
    </div>
    <list-table-read
      class="list-table"
      :initialDisplayFlg="initialDisplayFlg"
      :headerData="headers"
      :headerItemStyleData="styles"
      :hitAllDataCounts="hitAllDataCounts"
      :bodyData="bodyData"
      :allReadFlg="true"
      :allReadWaitFlg="waitFlg"
      @click="pushToReceivedMailShow"
      @read-all="readAll"
      @scroll-bottom="getNextData"
    />
    <announce-popup
      v-if="popupFlg"
      @close="closePopup"
      :title="title"
      :buttons="buttons"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import SearchArea from '@/components/parts/molecules/SearchArea'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import ListTableRead from '@/components/parts/organisms/ListTableRead.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { createDisplayBodyData } from '@/utils/list_table_read'
import BaseLoading from '@/components/parts/atoms/BaseLoading'

export default {
  name: 'ReceivedMails',

  components: {
    SearchArea,
    BaseSelectBox,
    ListTableRead,
    AnnouncePopup,
    BaseLoading
  },

  data() {
    return {
      bodyData: [],
      addBodyData: [],
      initialDisplayFlg: true,
      startDate: '',
      defaultStartDate: '',
      endDate: '',
      defaultEndDate: '',
      sortType: 2,
      sortTypes: [
        { id: 2, name: '受信日時' },
        { id: 4, name: '飼主ID' },
        { id: 5, name: '飼主名' },
        { id: 6, name: 'メールアドレス' }
      ],
      orderType: 1,
      orderTypes: [
        { id: 0, name: '昇順' },
        { id: 1, name: '降順' }
      ],
      radioButtonValue: 1,
      radioButtonData: [
        { id: 1, eachValue: 1, labelName: 'すべて' },
        { id: 2, eachValue: 2, labelName: '未読のみ' },
        { id: 3, eachValue: 3, labelName: '既読のみ' }
      ],
      headers: ['', '', '受信日時', '飼主ID', '飼主名', 'メールアドレス'],
      properties: [
        'read',
        'originDate', //←この値でsortしている
        'date',
        'ownerId',
        'ownerName',
        'email'
      ],
      styles: [
        {
          width: '25px',
          color: '#ffa62b',
          fontSize: '8px',
          overflow: 'visible',
          padding: '0'
        },
        { width: '0%', display: 'none' },
        { width: '24%' },
        { width: '25%' },
        { width: '25%' },
        { width: '25%' }
      ],
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      waitFlg: false,
      page: 0,
      isFull: true,
      hitAllDataCounts: 0,
      lastUpdated: '',
      dataChangeFlg: false,
      unsubscribe: null
    }
  },

  computed: {
    ...mapGetters({
      receivedMails: 'receivedMails/getData',
      getOwner: 'owners/getDataById'
    })
  },

  watch: {
    sortType() {
      this.resetAndGetData()
    },
    orderType() {
      this.resetAndGetData()
    }
  },

  created() {
    const startMoment = moment().subtract(7, 'd')
    this.startDate = startMoment.format('YYYYMMDD')
    this.defaultStartDate = startMoment.toDate()
    const endMoment = moment()
    this.endDate = endMoment.format('YYYYMMDD')
    this.defaultEndDate = endMoment.toDate()
    this.bodyData = this.createInitialBodyData()
    this.hitAllDataCounts = this.bodyData.length
    this.lastUpdated = moment()
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (
        mutation.type === 'receivedMails/updateAfter' &&
        mutation.payload.some(receivedMail => {
          const isDeletedDatum =
            receivedMail.delFlg === 1 &&
            moment(receivedMail.updatedAt).isSameOrAfter(this.lastUpdated)
          const isNewDatum = moment(receivedMail.createdAt).isSameOrAfter(
            this.lastUpdated
          )
          if (isDeletedDatum || isNewDatum) {
            // 最終更新日時より後に作られた新規データまたは削除データを判定の対象にする。
            if (
              (this.filterByDate(
                moment(receivedMail.date).format('YYYYMMDD')
              ) &&
                this.filterByRadioButton(receivedMail)) ||
              this.bodyData.find(v => v.id === receivedMail.id)
            ) {
              //検索条件に一致している、または画面内にデータが表示されている場合、更新を促すメッセージを表示
              return true
            } else {
              return false
            }
          } else {
            false
          }
        })
      ) {
        this.dataChangeFlg = true
      }
      if (mutation.type === 'receivedMails/updateAfter') {
        const markAsReadData = mutation.payload.filter(
          receivedMail =>
            receivedMail.readFlg === 1 &&
            receivedMail.delFlg === 0 &&
            moment(receivedMail.updatedAt).isSameOrAfter(this.lastUpdated)
        )
        for (const readMail of markAsReadData) {
          const index = this.bodyData.findIndex(v => v.id === readMail.id)
          if (index !== -1) this.bodyData[index].rowItems[0].itemText = ''
        }
      }
    })
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  methods: {
    filterByDate(date) {
      return this.startDate !== '' && this.endDate !== ''
        ? this.startDate <= date && date <= this.endDate
        : this.startDate !== ''
        ? this.startDate <= date
        : this.endDate !== ''
        ? date <= this.endDate
        : true
    },
    filterByRadioButton(receivedMail) {
      return this.radioButtonValue === 1
        ? true
        : this.radioButtonValue === 2
        ? receivedMail.readFlg === 0
        : receivedMail.readFlg === 1
    },
    makeDisplayDatum(rm) {
      const owner = this.getOwner(rm.ownerId)
      return {
        id: rm.id,
        read: rm.readFlg ? '' : '●',
        originDate: rm.date,
        date: moment(rm.date).format('YYYY年MM月DD日（dd）HH:mm'),
        ownerId: owner !== undefined ? owner.showId : '',
        ownerName:
          owner !== undefined ? `${owner.lastName} ${owner.firstName}` : '',
        email: owner !== undefined ? owner.email : ''
      }
    },
    createInitialBodyData() {
      const tmpBodyData = this.receivedMails.flatMap(rm => {
        const formatDate = moment(rm.date).format('YYYYMMDD')
        return this.startDate <= formatDate && formatDate <= this.endDate
          ? { ...this.makeDisplayDatum(rm), number: 0 }
          : []
      })
      return createDisplayBodyData(
        tmpBodyData,
        this.properties,
        this.styles,
        this.initialDisplayFlg,
        this.sortType
      )
    },
    createAddBodyData(resReceivedMails) {
      const lastKey = this.bodyData.length
      const tmpAddBodyData = resReceivedMails.map((rm, i) => {
        return { ...this.makeDisplayDatum(rm), number: lastKey + i }
      })
      return createDisplayBodyData(
        tmpAddBodyData,
        this.properties,
        this.styles,
        this.initialDisplayFlg,
        this.sortType
      )
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    changeRadioButtonValue(id) {
      this.radioButtonValue = id
    },
    async readAll(readIds) {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'receivedMails/readAll',
        readIds
      )
      if (result) {
        this.bodyData = this.bodyData.map(v => {
          if (readIds.includes(v.id)) {
            v.rowItems[0].itemText = ''
            return v
          } else {
            return v
          }
        })
      } else {
        this.popupFlg = true
        this.type = 'alert'
        this.title = 'お知らせ'
        this.buttons = ['閉じる']
        this.popupMessage = 'データが変更された可能性があります。'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.buttons = []
      this.popupMessage = ''
    },
    pushToReceivedMailShow(id) {
      this.$router.push({ path: `/main/notifications/received-mails/${id}` })
    },
    async resetAndGetData() {
      this.bodyData = []
      this.page = 0
      this.isFull = false
      this.dataChangeFlg = false
      await this.search()
    },
    async getNextData() {
      if (!this.isFull && !this.waitFlg) await this.search()
    },
    async search() {
      if (this.initialDisplayFlg) this.initialDisplayFlg = false
      this.waitFlg = true
      const res = await this.$store.dispatch('receivedMails/search', {
        startDate: this.startDate,
        endDate: this.endDate,
        sortType:
          this.sortType === 2
            ? 'date'
            : this.sortType === 4
            ? 'ownerShowId'
            : this.sortType === 5
            ? 'ownerName'
            : 'email',
        order: this.orderType === 1 ? 'DESC' : 'ASC',
        filter:
          this.radioButtonValue === 1
            ? 'all'
            : this.radioButtonValue === 2
            ? 'unread only'
            : 'read only',
        page: this.page
      })
      if (res.result === 'success') {
        if (this.page === 0) this.hitAllDataCounts = res.hitAllDataCounts
        this.page++
        this.bodyData = this.bodyData.concat(
          this.createAddBodyData(res.receivedMails)
        )
        if (this.bodyData.length < 100 || res.receivedMails.length === 0) {
          this.isFull = true
        }
        if (this.page >= 10 && this.bodyData.length >= 1000) {
          this.isFull = true
          this.popupFlg = true
          this.type = 'alert'
          this.title = '警告'
          this.buttons = ['閉じる']
          this.popupMessage = 'メールの表示件数が上限の1000件に達しました。'
        }
        this.lastUpdated = moment()
      } else {
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '通信エラーが発生しました。'
      }
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.received-mails {
  > p {
    font-size: 15px;
    font-weight: bold;
  }
  > .search-order {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-size: 13px;
    > .order-title {
      font-size: 15px;
      font-weight: bold;
    }
    > .data-change-message {
      margin-left: 20px;
      margin-top: 3px;
      font-size: 13px;
      font-weight: bold;
      color: #{$tomato};
    }
    > .order-setting {
      display: flex;
      align-items: center;
      margin-left: auto;
      > .select-box {
        margin-left: 10px;
      }
    }
  }
  > .list-table {
    height: calc(100vh - 400px);
    max-height: 664px; // 104px（件数ヘッダー部分の高さ）+ 56px * n（列の高さ×列数）
  }
}
</style>
